<template>
  <default-layout :breadcrumbs="breadcrumbs">
    <view-skeleton>
      <usage />
    </view-skeleton>
  </default-layout>
</template>

<script>
import DefaultLayout from '@/layouts/default';
import DefaultHeadMixin from '@/mixins/defaultHead';
import Usage from '@/components/coins-usage/usage';
import ViewSkeleton from '@/components/utils/view-skeleton';

export default {
  name: 'index',
  mixins: [DefaultHeadMixin],
  components: {
    DefaultLayout,
    Usage,
    ViewSkeleton,
  },
  computed: {
    breadcrumbs() {
      const currentName = this.$t('home.sidebarMenu.coinUsage');
      return [
        {
          text: this.$t('breadcrumb.homepage'),
          to: '/',
        },
        {
          text: currentName,
          to: this.$router.currentRoute.path,
        },
      ];
    },
  },
};
</script>
