<template>
  <section class="my--order-header">
    <div class="dashboard--search-container">
      <div class="search--order-wrapper">
        <div style="position: relative">
          <v-text-field
            :label="$t('coins.search')"
            :placeholder="$t('coins.placeholder.search')"
            outlined
            :hide-details="true"
            v-model="keyword"
            name="keyword"
            class="basic--input"
          ></v-text-field>
          <div class="search--button" @click="getUsages">
            <v-icon>mdi-magnify</v-icon>
          </div>
        </div>
        <div v-show="keyword" class="reset--search">
          <button type="button" @click="resetSearch">
            <i class="ion-close"></i>
            <span class="reset--text">{{ $t('coins.resetSearch') }}</span>
          </button>
        </div>
      </div>
    </div>
  </section>
</template>

<script>
import debounce from 'lodash/debounce';

export default {
  name: 'orders-header',
  components: {},
  computed: {
    keyword: {
      get() {
        return this.$store.state.coins.keyword;
      },
      set(value) {
        this.$store.commit('coins/SET_KEYWORD', value);
        this.getUsages();
      },
    },
  },
  methods: {
    resetSearch() {
      this.$store.commit('coins/SET_KEYWORD', null);
      this.$router.replace({ query: null });
      this.$store.dispatch('coins/getAllUsages', 1);
    },
    getUsages: debounce(function () {
      this.$router.replace({ query: { q: this.keyword } });
      this.$store.dispatch('coins/getAllUsages', 1);
    }, 500),
  },
  async mounted() {
    await this.$store.dispatch('coins/restoreInitiateUsages');
    let q = this.$route.query.q;
    this.$store.commit('coins/SET_KEYWORD', q);
    let page = this.$route.query.page;
    await this.$store.dispatch('coins/getAllUsages', page);
  },
};
</script>
