import { render, staticRenderFns } from "./coin-usage-header.vue?vue&type=template&id=240b22d2"
import script from "./coin-usage-header.vue?vue&type=script&lang=js"
export * from "./coin-usage-header.vue?vue&type=script&lang=js"


/* normalize component */
import normalizer from "!../../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  null,
  null
  
)

export default component.exports