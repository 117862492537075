<template>
  <div class="container mb-4">
    <div class="my--coin-usage">
      <coin-usage-header />
      <!--      <v-tabs center-active v-if="categories && categories.length > 0">-->
      <!--        <v-tab-->
      <!--          :href="`#tab-${index}`"-->
      <!--          v-for="(category, index) in categories"-->
      <!--          :key="`category-title-${index}`"-->
      <!--          @click="changeCategory(category)"-->
      <!--        >-->
      <!--          {{ category.name }}-->
      <!--        </v-tab>-->
      <!--        <v-tab-item-->
      <!--          :value="`tab-${index}`"-->
      <!--          v-for="(category, index) in categories"-->
      <!--          :key="`category-content-${index}`"-->
      <!--        >-->
      <SectionLoader :show="loading" />
      <v-simple-table class="mt-4">
        <thead>
          <tr>
            <th class="text-left">
              {{ $t('coins.date') }}
            </th>
            <th class="text-left">
              {{ $t('coins.listingName') }}
            </th>
            <th class="text-left">
              {{ $t('coins.activity') }}
            </th>
            <th class="text-left">
              {{ $t('coins.amount') }}
            </th>
          </tr>
        </thead>
        <tbody>
          <tr v-for="(usage, index) in usages" :key="`coin-usage-${index}`">
            <td>{{ $date.format(usage.used_at, 'dd MMM yyyy') }}</td>
            <td>
              <a
                :href="`${userWebsiteUrl}${usage.listing.url}`"
                target="_blank"
                v-if="usage.listing.url"
                >{{ usage.listing.title }}</a
              >
              <span v-else>{{ usage.listing.title }}</span>
            </td>
            <td>{{ usage.action }}</td>
            <td>{{ usage.coins_used }}</td>
          </tr>
        </tbody>
      </v-simple-table>
      <pagination
        :push-state="false"
        :meta="usagesMeta.pagination"
        @changePage="changePage"
        :maxVisibleButtons="5"
        v-if="usagesMeta"
      />
      <!--        </v-tab-item>-->
      <!--      </v-tabs>-->
    </div>
  </div>
</template>

<script>
import CoinUsageHeader from '@/components/coins-usage/coin-usage-header';
import SectionLoader from '@/components/content-loading/section-loading';
import Pagination from '@/components/utils/fractal-pagination';
import { mapState } from 'vuex';

export default {
  name: 'index',
  components: {
    CoinUsageHeader,
    SectionLoader,
    Pagination,
  },
  computed: {
    ...mapState({
      loading: (state) => state.coins.loading,
      categories: (state) => state.coins.categories,
      usages: (state) => state.coins.usages,
      usagesMeta: (state) => state.coins.usagesMeta,
    }),
    userWebsiteUrl() {
      return process.env.VUE_APP_USER_URL;
    },
  },
  methods: {
    async changePage(page) {
      await this.$store.dispatch('coins/getAllUsages', page);
    },
  },
  async mounted() {
    // await this.$store.dispatch('coins/getAllUsages', 1);
  },
};
</script>

<style lang="scss" scoped>
@import '../../assets/scss/utils/variables';
.container {
  padding-top: 0;
}
.my--coin-usage {
  border: solid 1px $color-gray-1;
  border-radius: 16px;
  padding: 24px 24px;

  @media #{$phones} {
    border: none;
    padding: 24px 0;
  }

  .v-data-table thead tr {
    background-color: $color-beliruma-1;

    th {
      color: $color-white !important;
    }
  }
}
</style>
